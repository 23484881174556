<template lang="html">
  <div class="statsContainer">
    <div v-for="(stats, index) in getTurnStats" v-bind:key="index">
      <div class="statContainer" v-if="index > 0 && index < 8">
        <strong>{{ getPercentage(getTurnStats[index]) }}%</strong> raadt het in
        {{ index }} keer<br />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StatsComponent",
  data: () => ({}),
  created() {
    this.$store.dispatch("fetchTotalGames");
    this.$store.dispatch("fetchTurnStats", 1);
    this.$store.dispatch("fetchTurnStats", 2);
    this.$store.dispatch("fetchTurnStats", 3);
    this.$store.dispatch("fetchTurnStats", 4);
    this.$store.dispatch("fetchTurnStats", 5);
    this.$store.dispatch("fetchTurnStats", 6);
    this.$store.dispatch("fetchTurnStats", 7);
  },
  computed: {
    getTurnStats: function () {
      return this.$store.getters.getTurnStats;
    },
    getTotalGames: function () {
      return this.$store.getters.getTotalGames;
    },
  },
  methods: {
    getPercentage: function (amount) {
      return Math.floor((100 * amount) / this.$store.getters.getTotalGames);
    },
  },
};
</script>

<style lang="css" scoped>
.statsContainer {
  margin: auto;
  margin-bottom: 40px;
}
.statContainer {
  margin: auto;
  margin-bottom: 20px;
  font-size: 55px;
  font-weight: 200;
  color: white;
  background-color: #7c6192;
  width: 60%;
  padding: 20px;
  border-radius: 50px;
  box-shadow: 8px 8px grey;
  white-space: nowrap;
}
.statsContainer strong {
  font-weight: 600;
}
</style>

import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import StartView from "../views/StartView.vue";
import NameView from "../views/NameView.vue";
import EmailView from "../views/EmailView.vue";
import CompanyView from "../views/CompanyView.vue";
import JobpositionView from "../views/JobpositionView.vue";
import GameInputView from "../views/GameInputView.vue";
import OutputView from "../views/OutputView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/start",
    name: "start",
    component: StartView,
  },
  {
    path: "/name",
    name: "name",
    component: NameView,
  },
  {
    path: "/email",
    name: "email",
    component: EmailView,
  },
  {
    path: "/company",
    name: "company",
    component: CompanyView,
  },
  {
    path: "/jobposition",
    name: "jobposition",
    component: JobpositionView,
  },
  {
    path: "/gameinput",
    name: "gameinput",
    component: GameInputView,
  },
  {
    path: "/output",
    name: "output",
    component: OutputView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

<template>
  <div class="start" style="text-align: center">
    <div class="minvh100 screen center-container">
      <div class="centered-element">
        <input
          v-model="value"
          class="form-control field no-focusborder"
          type="text"
          ref="inputfield"
          placeholder="Functie"
        />
        <a v-if="isGameActive" href="#" @click="updateActiveGame()">
          <img src="@/assets/NextButton.png" class="nextButton" />
        </a>
        <a v-if="isGameActive" href="#" @click="endOldActiveGame()">
          <img src="@/assets/stopButton.png" class="image" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "JobpositionView",
  components: {},
  mounted() {
    this.focusInput();
  },
  data: () => ({
    gameActive: false,
    value: null,
  }),
  created() {
    this.$store.dispatch("getActiveGame");
  },
  computed: {
    isGameActive: function () {
      return this.$store.getters.isGameActive;
    },
  },
  methods: {
    focusInput() {
      this.$refs.inputfield.focus();
    },
    endOldActiveGame: function () {
      let data = this.$store.getters.getGame;
      data.has_ended = true;
      this.$store.dispatch("updateActiveGame", data);
      this.$store.dispatch("getActiveGame");
      this.$router.push("/start");
    },
    updateActiveGame: function () {
      if (this.value) {
        let data = this.$store.getters.getGame;
        data.jobposition = this.value;
        data.has_ended = false;
        this.$store.dispatch("updateActiveGame", data);
        this.$router.push("/gameinput");
      }
    },
    startNewGame: function () {
      let data = {};
      data.code = this.getRandomCode();
      this.$store.dispatch("startNewGame", data);
    },
    getRandomCode: function () {
      let max = 31;
      let min = 1;
      let randomNumber = Math.floor(Math.random() * (max - min + 1) + min);
      let code = (randomNumber >>> 0).toString(2);
      let paddedNumber = String(code).padStart(5, "0");
      return paddedNumber;
    },
  },
};
</script>

<style lang="css" scoped>
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 0.4; /* Firefox */
}
html,
body {
  height: 100%;
  margin: 0px;
  padding: 0px;
}
.screen {
  background: url("~@/assets/bg_green_purple.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;
}
.nextButton {
  margin: auto;
  margin-bottom: 20px;
  display: block;
  width: 30%;
}
.image {
  margin: auto;
  width: 20%;
}
.center-container {
  position: relative;
  height: 100%;
  border: 2px solid #006100;
}
.centered-element {
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.field {
  margin: auto;
  outline: none;
  border: none;
  width: 60%;
  padding: 20px;
  font-size: 40px;
  border-radius: 20px;
  text-align: center;
  margin-bottom: 30px;
}
.no-focusborder:focus {
  outline-style: none;
  box-shadow: none;
  border-color: transparent;
  background-color: #7c6192;
  color: white;
}
.minvh100 {
  min-height: 100vh !important;
  min-height: -webkit-fill-available;
}
</style>

<template>
  <div class="vh-100 screen" style="text-align: center">
    <router-link :to="{ name: 'output' }">
      <div class="statContainer">Output</div>
    </router-link>
    <router-link :to="{ name: 'start' }">
      <div class="statContainer">Input</div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  components: {},
};
</script>

<style>
.screen {
  background: url("~@/assets/bg_green_purple.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;
  padding-top: 10%;
}
.statsContainer {
  margin: auto;
  margin-bottom: 40px;
}
.statContainer {
  margin: auto;
  margin-bottom: 20px;
  font-size: 55px;
  font-weight: 200;
  color: white;
  background-color: #7c6192;
  width: 60%;
  padding: 20px;
  border-radius: 50px;
  box-shadow: 8px 8px grey;
  white-space: nowrap;
}
.statsContainer strong {
  font-weight: 600;
}
a {
  text-decoration: none;
}
</style>

<template lang="html">
  <div class="vh-100 InactiveScreen contentContainer">
    <img src="@/assets/kraakJijDeCode.png" class="image" alt="" />
    <table class="gametable center">
      <tr v-for="index in getBlankRowAmount" v-bind:key="index">
        <td class="whiteBackground"></td>
        <td class="whiteBackground"></td>
        <td class="whiteBackground"></td>
        <td class="whiteBackground"></td>
        <td class="whiteBackground"></td>
        <td class="purpleBackground"></td>
        <td class="greenBackground"></td>
      </tr>
      <tr v-for="guess in getGameGuesses" v-bind:key="guess">
        <td class="whiteBackground" v-for="num in guess" v-bind:key="num">
          <div :class="num == 0 ? 'code_0' : 'code_1'"></div>
        </td>
        <td class="purpleBackground">
          <div :class="getAmountCorrect(guess, getGameCode)"></div>
        </td>
        <td class="greenBackground">
          <div :class="getAmountCorrectPlace(guess, getGameCode)"></div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: "GameOutputScreen",
  data: () => ({
    rows: [1, 2, 3, 4],
  }),
  computed: {
    activeGame: function () {
      return this.$store.getters.getGame;
    },
    getBlankRowAmount: function () {
      let gameGuesses = this.$store.getters.getGameGuesses;
      if (gameGuesses) {
        return 7 - gameGuesses.length;
      }
      return 7;
    },
    getGameGuesses: function () {
      return this.$store.getters.getGameGuesses;
    },
    getGameCode: function () {
      let game = this.$store.getters.getGame;
      return game.code;
    },
  },
  methods: {
    getAmountCorrect: function (guess, code) {
      let returnValue = 0;

      let guessHasZeros = this.getZeroOrOneCount(guess, 0);
      let guessHasOnes = this.getZeroOrOneCount(guess, 1);

      let gameCodeHasZeros = this.getZeroOrOneCount(code, 0);
      let gameCodeHasOnes = this.getZeroOrOneCount(code, 1);

      if (guessHasZeros < gameCodeHasZeros) {
        returnValue = returnValue + guessHasZeros;
      } else if (guessHasZeros > gameCodeHasZeros) {
        returnValue = returnValue + gameCodeHasZeros;
      } else {
        returnValue = returnValue + guessHasZeros;
      }

      if (guessHasOnes < gameCodeHasOnes) {
        returnValue = returnValue + guessHasOnes;
      } else if (guessHasOnes > gameCodeHasOnes) {
        returnValue = returnValue + gameCodeHasOnes;
      } else {
        returnValue = returnValue + guessHasOnes;
      }
      return "num_" + returnValue;
    },
    getAmountCorrectPlace: function (guess, code) {
      let correctCount = 0;

      for (var i = 0; i < guess.length; i++) {
        if (guess[i] == code[i]) {
          correctCount += 1;
        }
      }
      console.log(correctCount);
      return "num_" + correctCount;
    },
    getZeroOrOneCount: function (guess, zeroOrOne) {
      let zeroCount = 0;

      for (var i = 0; i < guess.length; i++) {
        if (guess[i] == zeroOrOne) {
          zeroCount += 1;
        }
      }
      return zeroCount;
    },
  },
};
</script>

<style lang="css" scoped>
html,
body {
  height: 100%;
  margin: 0px;
  padding: 0px;
}
.InactiveScreen {
  background: url("~@/assets/bg_green_purple.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;
}
.image {
  width: 60%;
}
.contentContainer {
  padding-top: 10%;
}
.gametable {
  margin-top: 5%;
  width: 90%;
  overflow-wrap: break-word;
}
.gametable td {
}
.gametable td image {
  width: 90%;
}
.center {
  margin-left: auto;
  margin-right: auto;
}
.whiteBackground {
  background: url("~@/assets/num_back_white.png") no-repeat;
  background-size: contain;
  height: 190px;
  /* width: 100px; */
}
.purpleBackground {
  background: url("~@/assets/num_back_purple.png") no-repeat;
  background-size: contain;
  height: 190px;
  /* width: 100px; */
}
.greenBackground {
  background: url("~@/assets/num_back_green.png") no-repeat;
  background-size: contain;
  height: 190px;
  /* width: 100px; */
}
.code_0 {
  background: url("~@/assets/code_0.png") no-repeat;
  background-size: contain;
  height: 100%;
}
.code_1 {
  background: url("~@/assets/code_1.png") no-repeat;
  background-size: contain;
  height: 100%;
}
.num_0 {
  background: url("~@/assets/num_0.png") no-repeat;
  background-size: contain;
  height: 100%;
}
.num_1 {
  background: url("~@/assets/num_1.png") no-repeat;
  background-size: contain;
  height: 100%;
}
.num_2 {
  background: url("~@/assets/num_2.png") no-repeat;
  background-size: contain;
  height: 100%;
}
.num_3 {
  background: url("~@/assets/num_3.png") no-repeat;
  background-size: contain;
  height: 100%;
}
.num_4 {
  background: url("~@/assets/num_4.png") no-repeat;
  background-size: contain;
  height: 100%;
}
.num_5 {
  background: url("~@/assets/num_5.png") no-repeat;
  background-size: contain;
  height: 100%;
}
</style>

<template lang="html">
  <div class="vh-100 InactiveScreen">
    <div class="contentContainer">
      <img src="@/assets/kraakJijDeCode.png" class="image" alt="" />
      <StatsComponent />
      <img src="@/assets/drukOpStart.png" class="imageButton" />
    </div>
  </div>
</template>

<script>
import StatsComponent from "@/components/StatsComponent.vue";

export default {
  name: "InactiveScreen",
  components: {
    StatsComponent,
  },
  data: () => ({
    stats: false,
  }),
  created() {
    this.timer = setInterval(this.switchStats, 2000);
  },
  methods: {
    switchStats: function () {
      this.stats = !this.stats;
    },
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="css" scoped>
html,
body {
  height: 100%;
  margin: 0px;
  padding: 0px;
}
.InactiveScreen {
  background: url("~@/assets/bg_green_purple.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;
}
.image {
  width: 70%;
}
.imageButton {
  width: 60%;
  margin-bottom: 40px;
}
.contentContainer {
  padding-top: 15%;
}
</style>

import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";
import axios from "axios";
import router from "../router";

const vuexLocalStorage = new VuexPersistence({
  key: "vuex", // The key to store the state on in the storage provider.
  storage: window.localStorage, // or window.sessionStorage or localForage
  // Function that passes the state and returns the state with only the objects you want to store.
  // reducer: (state) => state,
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  // filter: mutation => (true)
});

export default createStore({
  plugins: [vuexLocalStorage.plugin],
  state: {
    loading: false,
    game: null,
    gameStart: false,
    gameActive: false,
    gameEnd: false,
    gamesCount: null,
    gamesWon: null,
    gamesLost: null,
    answers: [],
    answersCount: null,
    code: null,
    turnStats: [],
    wonStats: 0,
    totalGames: 0,
  },
  getters: {
    getLoading(state) {
      return state.loading;
    },
    getGame(state) {
      return state.game;
    },
    getGameGuesses(state) {
      return state.game.guesses;
    },
    getGameId(state) {
      let game = state.game;
      if (game) {
        return game._id;
      }
      return false;
    },
    hasGamePlayer(state) {
      if (state.game.name && state.game.email) {
        return true;
      }
      return false;
    },
    gameHasName(state) {
      if (state.game.name) {
        return true;
      }
      return false;
    },
    gameHasEmail(state) {
      if (state.game.email) {
        return true;
      }
      return false;
    },
    gameHasCompany(state) {
      if (state.game.company) {
        return true;
      }
      return false;
    },
    gameHasJobposition(state) {
      if (state.game.jobposition) {
        return true;
      }
      return false;
    },
    gameHasPersonalInfo(state) {
      if (
        state.game.name &&
        state.game.company &&
        state.game.company &&
        state.game.jobposition
      ) {
        return true;
      }
      return false;
    },
    isGameActive(state) {
      if (state.game) {
        return true;
      }
      return false;
    },
    isGameEnd(state) {
      if (state.game) {
        return state.game.gameover;
      }
      return false;
    },
    getGamesCount(state) {
      return state.gamesCount;
    },
    getGamesWon(state) {
      return state.gamesWon;
    },
    getGamesLost(state) {
      return state.gamesLost;
    },
    getTurn(state) {
      return state.game.answers.length + 1;
    },
    getAnswers(state) {
      if (state.game.answers) {
        return state.game.answers;
      }
      return [];
    },
    getAnswersCount(state) {
      return state.answersCount;
    },
    getCode(state) {
      return state.game.code;
    },
    getTotalGames(state) {
      return state.totalGames;
    },
    getTurnStats(state) {
      return state.turnStats;
    },
  },
  mutations: {
    setLoading(state, bool) {
      state.loading = bool;
    },
    setActiveGame(state, game) {
      // console.log(game);
      state.game = game;
    },
    setTurnStats(state, stats) {
      state.turnStats[stats.turn] = stats.total;
    },
    setWonStats(state, total) {
      state.wonStats = total;
    },
    setTotalGames(state, total) {
      state.totalGames = total;
    },
  },
  actions: {
    getActiveGame(state) {
      axios
        .get(
          process.env.VUE_APP_COCKPITURL +
            "api/collections/get/game?token=" +
            process.env.VUE_APP_TOKEN,
          {
            params: {
              limit: 1,
              sort: { _created: -1 },
              filter: { has_ended: false },
            },
          }
        )
        .then((response) => {
          state.commit("setLoading", false);
          let game = response.data.entries[0];
          if (game) {
            state.commit("setActiveGame", game);
            // check if won
            let guesses = game.guesses;
            let code = game.code;
            if (guesses) {
              if (guesses.includes(code)) {
                game.has_won = true;
                state.dispatch("updateActiveGame", game);
              }
            }
          } else {
            state.commit("setActiveGame", null);
          }
        })
        .catch((err) => console.log(err));
    },
    fetchTurnStats(state, payload) {
      axios
        .get(
          process.env.VUE_APP_COCKPITURL +
            "api/collections/get/game?token=" +
            process.env.VUE_APP_TOKEN,
          {
            params: {
              limit: 1,
              filter: { turns: payload, has_won: true },
            },
          }
        )
        .then((response) => {
          let stats = {};
          stats.turn = payload;
          stats.total = response.data.total;
          state.commit("setTurnStats", stats);
        })
        .catch((err) => console.log(err));
    },
    fetchWonStats(state) {
      axios
        .get(
          process.env.VUE_APP_COCKPITURL +
            "api/collections/get/game?token=" +
            process.env.VUE_APP_TOKEN,
          {
            params: {
              limit: 1,
              filter: { has_won: true },
            },
          }
        )
        .then((response) => {
          state.commit("setWonStats", response.data.total);
        })
        .catch((err) => console.log(err));
    },
    fetchTotalGames(state) {
      axios
        .get(
          process.env.VUE_APP_COCKPITURL +
            "api/collections/get/game?token=" +
            process.env.VUE_APP_TOKEN,
          {
            params: {
              limit: 1,
            },
          }
        )
        .then((response) => {
          state.commit("setTotalGames", response.data.total);
        })
        .catch((err) => console.log(err));
    },
    addName() {},
    addEmail() {},
    addAnswer() {},
    updateActiveGame(state, data) {
      state.commit("setLoading", true);
      let token = process.env.VUE_APP_TOKEN;
      // console.log(data);
      axios
        .post(
          process.env.VUE_APP_COCKPITURL +
            "api/collections/save/game?token=" +
            token,
          { data }
        )
        .then((response) => {
          state.commit("setActiveGame", response.data);
        })
        .catch((err) => console.log(err));
    },
    startNewGame(state, data) {
      state.commit("setLoading", true);
      let token = process.env.VUE_APP_TOKEN;
      axios
        .post(
          process.env.VUE_APP_COCKPITURL +
            "api/collections/save/game?token=" +
            token,
          { data }
        )
        .then((response) => {
          console.log(response);
          router.push("/name");
        })
        .catch((err) => console.log(err));
    },
  },
  modules: {},
});

<template>
  <div class="start" style="text-align: center">
    <div class="vh-100 screen center-container">
      <div class="centered-element">
        <div class="EndImage">
          <div v-if="hasWon">
            <img src="@/assets/winner.png" class="image" alt="" />
          </div>
          <div v-if="hasLost">
            <img src="@/assets/looser.png" class="image" alt="" />
            <h1>De code was {{ getCode }}</h1>
          </div>
        </div>
        <div v-if="!hasWon && !hasLost">
          <table class="gametable center" v-if="isGameActive">
            <tr>
              <td class="whiteBackground">
                <div
                  :class="getCodeClass(code[0])"
                  @click="changeCodeNumber(0)"
                ></div>
              </td>
              <td class="whiteBackground">
                <div
                  :class="getCodeClass(code[1])"
                  @click="changeCodeNumber(1)"
                ></div>
              </td>
              <td class="whiteBackground">
                <div
                  :class="getCodeClass(code[2])"
                  @click="changeCodeNumber(2)"
                ></div>
              </td>
              <td class="whiteBackground">
                <div
                  :class="getCodeClass(code[3])"
                  @click="changeCodeNumber(3)"
                ></div>
              </td>
              <td class="whiteBackground">
                <div
                  :class="getCodeClass(code[4])"
                  @click="changeCodeNumber(4)"
                ></div>
              </td>
            </tr>
          </table>
          <!-- {{ hasWon }} {{ hasLost }} {{ disableSend }} -->
          <div v-if="isGameActive">
            <div v-if="!disableSend">
              <a href="#" @click="updateActiveGame()">
                <img src="@/assets/verstuurButton.png" class="nextButton" />
              </a>
            </div>
            <div v-else>
              <img src="@/assets/VerstuurDisabled.png" class="nextButton" />
            </div>
          </div>
        </div>
        <a v-if="isGameActive" href="#" @click="endOldActiveGame()">
          <img src="@/assets/stopButton.png" class="image" />
        </a>
        <a v-if="!isGameActive" href="#" @click="toStartScreen()">
          <img src="@/assets/stopButton.png" class="image" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GameInputView",
  components: {},
  data: () => ({
    gameActive: false,
    code: "?????",
  }),
  created() {
    this.$store.dispatch("getActiveGame");
  },
  computed: {
    getCode: function () {
      return this.$store.getters.getCode;
    },
    isGameActive: function () {
      return this.$store.getters.isGameActive;
    },
    disableSend: function () {
      let game = this.$store.getters.getGame;
      if (game) {
        let guesses = game.guesses;
        if (guesses) {
          if (guesses.includes(this.code)) {
            return true;
          }
        }
      }
      return false;
    },
    hasWon: function () {
      let game = this.$store.getters.getGame;
      if (game) {
        let guesses = game.guesses;
        let code = game.code;
        if (guesses) {
          if (guesses.includes(code)) {
            return true;
          }
        }
      }
      return false;
    },
    hasLost: function () {
      let game = this.$store.getters.getGame;
      if (game) {
        let guesses = game.guesses;
        if (guesses) {
          if (guesses.length > 6) {
            return true;
          }
        }
      }
      return false;
    },
  },
  methods: {
    toStartScreen: function () {
      this.$router.push("/start");
    },
    endOldActiveGame: function () {
      let data = this.$store.getters.getGame;
      data.has_ended = true;
      data.has_won = this.hasWon;
      this.$store.dispatch("updateActiveGame", data);
      this.$store.dispatch("getActiveGame");
      this.$router.push("/start");
    },
    updateActiveGame: function () {
      if (!this.code.includes("?")) {
        let data = this.$store.getters.getGame;
        if (data.guesses) {
          data.guesses.push(this.code);
        } else {
          data.guesses = [];
          data.guesses.push(this.code);
        }
        data.turns = data.guesses.length;
        data.has_ended = false;
        this.$store.dispatch("updateActiveGame", data);
      }
    },
    startNewGame: function () {
      let data = {};
      data.code = this.getRandomCode();
      this.$store.dispatch("startNewGame", data);
    },
    getRandomCode: function () {
      let max = 31;
      let min = 1;
      let randomNumber = Math.floor(Math.random() * (max - min + 1) + min);
      let code = (randomNumber >>> 0).toString(2);
      let paddedNumber = String(code).padStart(5, "0");
      return paddedNumber;
    },
    getCodeClass: function (value) {
      if (value == 0) {
        return "code_0";
      } else if (value == 1) {
        return "code_1";
      } else {
        return "code_null";
      }
    },
    changeCodeNumber: function (pos) {
      let code = this.code;
      if (this.code[pos] == "?") {
        code = this.code.substring(0, pos) + "0" + this.code.substring(pos + 1);
      } else if (this.code[pos] == "0") {
        code = this.code.substring(0, pos) + "1" + this.code.substring(pos + 1);
      } else {
        code = this.code.substring(0, pos) + "0" + this.code.substring(pos + 1);
      }
      this.code = code;
    },
  },
};
</script>

<style lang="css" scoped>
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 0.4; /* Firefox */
}
html,
body {
  height: 100%;
  margin: 0px;
  padding: 0px;
}
.screen {
  background: url("~@/assets/bg_green_purple.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;
}
.nextButton {
  margin: auto;
  margin-bottom: 20px;
  display: block;
  width: 30%;
}
.image {
  margin: auto;
  width: 30%;
  margin-bottom: 10px;
}
.center-container {
  position: relative;
  height: 100%;
  border: 2px solid #006100;
}
.centered-element {
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.field {
  margin: auto;
  outline: none;
  border: none;
  width: 60%;
  padding: 20px;
  font-size: 40px;
  border-radius: 20px;
  text-align: center;
  margin-bottom: 30px;
}
.no-focusborder:focus {
  outline-style: none;
  box-shadow: none;
  border-color: transparent;
  background-color: #7c6192;
  color: white;
}
.code_null {
  background: url("~@/assets/code_null.png") no-repeat;
  background-size: contain;
  height: 100%;
}
.code_0 {
  background: url("~@/assets/code_0.png") no-repeat;
  background-size: contain;
  height: 100%;
}
.code_1 {
  background: url("~@/assets/code_1.png") no-repeat;
  background-size: contain;
  height: 100%;
}
.whiteBackground {
  background: url("~@/assets/num_back_white.png") no-repeat;
  background-size: contain;
  height: 190px;
  /* width: 100px; */
}
.gametable {
  margin-bottom: 20px;
  width: 70%;
  overflow-wrap: break-word;
}
.gametable td {
}
.gametable td image {
  width: 70%;
}
.center {
  margin-left: auto;
  margin-right: auto;
}
.winLooseScreen {
  width: 50%;
}
h1 {
  font-weight: 600;
  color: white;
}
.EndImage {
  margin-bottom: 20px;
}
</style>

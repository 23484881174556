<template lang="html">
  <div class="vh-100 InactiveScreen">
    <div class="contentContainer">
      <img src="@/assets/winner.png" class="image" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "WinnerScreen",
  created() {
    // let data = this.$store.getters.getGame;
    // data.has_won = true;
    // this.$store.dispatch("updateActiveGame", data);
    // this.timer = setInterval(this.gameEnd, 7000);
  },
  methods: {
    gameEnd: function () {
      let data = this.$store.getters.getGame;
      data.has_ended = true;
      data.has_won = true;
      this.$store.dispatch("updateActiveGame", data);
    },
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="css" scoped>
html,
body {
  height: 100%;
  margin: 0px;
  padding: 0px;
}
.InactiveScreen {
  background: url("~@/assets/bg_green_purple.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;
}
.image {
  width: 80%;
}
.contentContainer {
  padding-top: 25%;
}
</style>

<template>
  <div class="start" style="text-align: center">
    <div class="vh-100 screen center-container">
      <div class="centered-element">
        <a v-if="!isGameActive" href="#" @click="startNewGame()">
          <img src="@/assets/startButton.png" class="image" />
        </a>
        <a v-if="isGameActive" href="#" @click="endOldActiveGame()">
          <img src="@/assets/stopButton.png" class="image" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StartView",
  components: {},
  data: () => ({
    gameActive: false,
  }),
  created() {
    this.$store.dispatch("getActiveGame");
  },
  computed: {
    isGameActive: function () {
      return this.$store.getters.isGameActive;
    },
  },
  methods: {
    endOldActiveGame: function () {
      let data = this.$store.getters.getGame;
      data.has_ended = true;
      this.$store.dispatch("updateActiveGame", data);
      this.$store.dispatch("getActiveGame");
    },
    startNewGame: function () {
      let data = {};
      data.code = this.getRandomCode();
      this.$store.dispatch("startNewGame", data);
    },
    getRandomCode: function () {
      let max = 31;
      let min = 1;
      let randomNumber = Math.floor(Math.random() * (max - min + 1) + min);
      let code = (randomNumber >>> 0).toString(2);
      let paddedNumber = String(code).padStart(5, "0");
      return paddedNumber;
    },
  },
};
</script>

<style lang="css" scoped>
html,
body {
  height: 100%;
  margin: 0px;
  padding: 0px;
}
.screen {
  background: url("~@/assets/bg_green_purple.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;
}
.image {
  margin: auto;
  width: 50%;
}
.center-container {
  position: relative;
  height: 100%;
  border: 2px solid #006100;
}
.centered-element {
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
</style>

<template lang="html">
  <div class="vh-100 background">
    <div class="contentContainer">
      <img src="@/assets/kraakJijDeCode.png" class="image" alt="" />
      <img src="@/assets/vulNaamIn.png" class="image" v-if="!gameHasName" />
      <img
        src="@/assets/vulEmailadresIn.png"
        class="image"
        v-if="gameHasName && !gameHasEmail"
      />
      <img
        src="@/assets/vulBedrijfIn.png"
        class="image"
        v-if="gameHasName && gameHasEmail && !gameHasCompany"
      />
      <img
        src="@/assets/vulFunctieIn.png"
        class="image"
        v-if="
          gameHasName && gameHasEmail && gameHasCompany && !gameHasJobposition
        "
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "PersonalInfoScreen",
  computed: {
    gameHasName: function () {
      return this.$store.getters.gameHasName;
    },
    gameHasEmail: function () {
      return this.$store.getters.gameHasEmail;
    },
    gameHasCompany: function () {
      return this.$store.getters.gameHasCompany;
    },
    gameHasJobposition: function () {
      return this.$store.getters.gameHasJobposition;
    },
  },
};
</script>

<style lang="css" scoped>
html,
body {
  height: 100%;
  margin: 0px;
  padding: 0px;
}
.background {
  background: url("~@/assets/bg_green_purple.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;
}
.image {
  width: 80%;
}
.contentContainer {
  padding-top: 25%;
}
</style>

<template lang="html">
  <div style="text-align: center">
    <InactiveScreen v-if="!isGameActive" />
    <PersonalInfoScreen v-if="isGameActive && !gameHasPersonalInfo" />
    <GameOutputScreen
      v-if="isGameActive && gameHasPersonalInfo && !hasWon && !hasLost"
    />
    <WinnerScreen v-if="hasWon" />
    <LooserScreen v-if="hasLost" />
  </div>
</template>

<script>
import InactiveScreen from "@/components/InactiveScreen.vue";
import PersonalInfoScreen from "@/components/PersonalInfoScreen.vue";
import GameOutputScreen from "@/components/GameOutputScreen.vue";
import WinnerScreen from "@/components/WinnerScreen.vue";
import LooserScreen from "@/components/LooserScreen.vue";

export default {
  name: "OutputView",
  components: {
    InactiveScreen,
    PersonalInfoScreen,
    GameOutputScreen,
    WinnerScreen,
    LooserScreen,
  },
  data: () => ({
    waiting: false,
    gameStart: false,
    gameActive: false,
    gameEnd: false,
  }),
  created() {
    this.getGame();
    this.timer = setInterval(this.getGame, 2000);
  },
  computed: {
    isGameActive: function () {
      return this.$store.getters.isGameActive;
    },
    gameHasPersonalInfo: function () {
      return this.$store.getters.gameHasPersonalInfo;
    },
    hasWon: function () {
      let game = this.$store.getters.getGame;
      if (game) {
        let guesses = game.guesses;
        let code = game.code;
        if (guesses) {
          if (guesses[guesses.length - 1] == code) {
            return true;
          }
        }
      }
      return false;
    },
    hasLost: function () {
      let game = this.$store.getters.getGame;
      if (game) {
        let guesses = game.guesses;
        if (guesses) {
          if (guesses.length > 6) {
            return true;
          }
        }
      }
      return false;
    },
  },
  methods: {
    getGame: function () {
      this.$store.dispatch("getActiveGame");
    },
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="css" scoped></style>
